window.addEventListener('DOMContentLoaded', () => {
	const openLang = document.querySelector(".tp_lang__open");
	const langBlock = document.querySelector(".tp_lang form");
	const openSearch = document.querySelector(".open-search");
	const closeSeacrh = document.querySelector(".close-search");
	const searchModal = document.querySelector(".tp_search__modal");
	const openMenu = document.querySelector("#open-menu");
	const menu = document.querySelector(".nav-menu");
	const subMenuBtns = document.querySelectorAll("[data-subMenuBtn]");
	const subMenus = document.querySelectorAll("[data-subMenu]");
	const joinForm = document.querySelector("form#join");

	openMenu.addEventListener("click", () => {
		if (!openMenu.classList.contains("open")) {
			openMenu.classList.add("open");
			menu.classList.add("active_nav");
		} else {
			openMenu.removeAttribute("class");
			menu.classList.remove("active_nav");
		}
	});

	subMenuBtns.forEach((btn, i) => {
		btn.addEventListener("click", (e) => {
			e.preventDefault();

			subMenuBtns.forEach(removeBtnCLassList => {
				removeBtnCLassList.classList.remove("active-link");
			});

			subMenus.forEach((subMenuItem, index) => {
				subMenuItem.removeAttribute("class");

				if (i === index) {
					btn.classList.add("active-link");
					subMenuItem.classList.add("active_submenu");
				}

				subMenuItem.addEventListener("mouseleave", (e) => {
					subMenuItem.removeAttribute("class");
					e.target.previousElementSibling.removeAttribute("class");
				});
			});
		});
	});

	openLang.addEventListener("click", () => {
		if (!langBlock.classList.contains("tp_active__lang")) {
			langBlock.classList.add("tp_active__lang");
		} else {
			langBlock.removeAttribute("class");
		}
	});

	langBlock.addEventListener("mouseleave", () => {
		langBlock.removeAttribute("class");
	});

	openSearch.addEventListener("click", () => {
		searchModal.classList.add("tp_active__search");
	});

	closeSeacrh.addEventListener("click", (e) => {
		e.target.parentElement
			.parentElement.classList
			.remove("tp_active__search");
	});

	try {
		const allSlides = document.querySelectorAll(".header_slider .swiper-wrapper .swiper-slide");
		allSlides.forEach((item, index) => {
			if (index === 0) {
				const title = item.querySelector("h2");
				const text = item.querySelector("p");
				text.style.cssText = "font-style: italic;";
			}
		});

		new Swiper(".header_slider", {
			direction: "vertical",
			loop: true,
			spaceBetween: 200,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
		});

		new Swiper(".our_team__slider", {
			loop: true,
			spaceBetween: 30,
			pagination: {
				el: ".swiper-pagination",
				clickable: true
			},
		});
	} catch { }

	const regAM = /[\u0530-\u058F]/;
	const regNUM = /^[0-9 ()+]+$/;
	const red = "#ee142e";

	// form inputs check only Armenian letters
	try {
		document.querySelectorAll("[data-arm]").forEach(input => {
			const inputPlaceholder = input.placeholder;

			input.addEventListener("input", (e) => {
				let val = e.target.value.trim();
				let rem;

				if (!regAM.test(val) || val === "") {
					e.target.style.borderColor = "red";
					rem = setTimeout(() => {
						input.value = "";
						input.placeholder = inputPlaceholder + " " + input.dataset.arm;
					}, 100);

				} else {
					e.target.style.borderColor = "";
					clearTimeout(rem);
				}
			});
		});
	} catch (e) { }

	// form telephone check function
	try {
		const joinFormTel = joinForm.querySelector("input[type='tel']");
		const inputPlaceholder = joinFormTel.placeholder;

		joinFormTel.addEventListener("input", (e) => {
			let rem;
			let val = e.target.value.trim();

			if (!regNUM.test(val) || val === "") {
				e.target.style.borderColor = "red";

				rem = setTimeout(() => {
					e.target.value = "";
					e.target.placeholder = inputPlaceholder + " " + e.target.dataset.mobile;
				}, 100);

			} else {
				e.target.style.borderColor = "";
				clearTimeout(rem);
			}
		});
	} catch (e) { }

	// check inputs value
	function checkInput(input) {
		let impVal = input.placeholder;

		if (input.value.trim() === "" || input.value.trim() === undefined) {
			input.style.borderColor = red;
			input.placeholder = impVal + " " + input.dataset.valid;
		} else {
			input.style.borderColor = "";
			input.placeholder = "";
		}
	}

	// join form submit function / async with fetch
	try {
		joinForm.addEventListener("submit", (e) => {
			e.preventDefault();
			const checkbox = e.target.querySelector("input[type='checkbox']");

			e.target.querySelectorAll("[data-valid]").forEach(input => {
				input.addEventListener("input", () => {
					checkInput(input);
				});
				checkInput(input);
			});

			if (!checkbox.checked) {
				checkbox.parentElement.style.color = red;
			} else {
				checkbox.parentElement.style.color = "";
				const formData = new FormData(e.target);

				fetch(window.location.pathname, {
					method: 'POST',
					body: formData
				})
					.then(data => data.json())
					.then(data => {
						if (data.success === true) {
							e.target.insertAdjacentHTML("beforeend", `<p id="success"> ${data.message} </p>`);
							e.target.reset();
						} else {
							e.target.insertAdjacentHTML("beforeend", `<p id="error"> ${data.message} </p>`);
						}
					})
					.catch((e) => {
						console.error(e + "please try again !!!");
					});
			}
		});
	} catch (e) { }

	// print btn & function
	try {
		const printBtn = document.querySelector(".print");
		printBtn.addEventListener("click", () => {
			window.print();
		});
	} catch { }
});